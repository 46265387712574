import { useMutation } from '@tanstack/react-query';
import type { UseMutationResult } from '@tanstack/react-query';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import type SupplierInvoiceModel from 'qonto/models/supplier-invoice';

export const useMarkSupplierInvoiceAsPaid = (
  supplierInvoiceId: string
): UseMutationResult<SupplierInvoiceModel, Error, string> => {
  const store = useEmberService('store');

  const markAsPaid = async (paymentDate: string): Promise<SupplierInvoiceModel> => {
    const invoice = await store.findRecord('supplier-invoice', supplierInvoiceId);
    await invoice.markAsPaid(paymentDate);
    return invoice;
  };

  return useMutation({
    throwOnError: true,
    mutationKey: ['supplierInvoices', 'detail', supplierInvoiceId],
    mutationFn: markAsPaid,
  });
};
